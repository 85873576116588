
import { defineComponent, PropType } from 'vue'
import { DebouncedFunc, debounce } from 'lodash'
import { EnrolmentRequestFirestoreDoc } from 'types/enrolment'
import ApproveConfirmModal from './ApproveConfirmModal.vue'
import RejectConfirmModal from './RejectConfirmModal.vue'
import { firestore } from 'firebase'
import { functions, userData } from '@/firebase'
import { FunctionNames } from '@/enums/FunctionNames'
import {
  CheckForValidHLAPIKeyPayload,
  CheckForDuplicatedHLLocationIDPayload,
} from 'types/callable'
import {
  approveEnrolmentRequest,
  rejectEnrolmentRequest,
} from '@/composables/useEnrolmentRequests'

export default defineComponent({
  components: {
    ApproveConfirmModal,
    RejectConfirmModal,
  },
  props: {
    enrolmentData: {
      type: Object as PropType<EnrolmentRequestFirestoreDoc>,
      required: true,
    },
  },
  data() {
    return {
      showApproveModal: false,
      showRejectModal: false,
      form: {
        apiKey: '',
        locationId: '',
        confirmCampaign: false,
      },
      isApproving: false,
      isRejecting: false,
      isValidApiKey: 'unchecked' as 'unchecked' | 'valid' | 'invalid',
      isDuplicatedLocationID: 'unchecked' as
        | 'unchecked'
        | 'duplicated'
        | 'available',
      debouncedValidateApiKey: null as DebouncedFunc<() => void> | null,
      debouncedValidateLocationID: null as DebouncedFunc<() => void> | null,
    }
  },
  computed: {
    userRole() {
      return userData?.claims?.role ?? 'unauthorized'
    },
    isAllowToReview(): boolean {
      return this.userRole === 'admin' || this.userRole === 'taxnitro-reviewer'
    },
    alreadyReviewed(): null | { reviewerName: string; reviewedAt: string } {
      const isReviewed = this.enrolmentData?.taxNitroReviewed ?? false
      if (!isReviewed) return null
      const reviewerName =
        this.enrolmentData?.taxNitroReviewer?.reviewerName || 'Unknown'
      const timestamp = this.enrolmentData?.taxNitroReviewer
        ?.reviewedAt as firestore.Timestamp | null
      const reviewedAt = timestamp?.toDate().toLocaleString() ?? 'Unknown'
      return {
        reviewerName,
        reviewedAt,
      }
    },
    companyNameOnTaxNitro(): string {
      return this.enrolmentData?.companyNameOnNitro ?? ''
    },
    campaignsToEnroll(): string[] {
      return this.enrolmentData?.whichCampaigns ?? ['Non chosen']
    },
  },
  methods: {
    async reject(reason: string) {
      if (!reason) return
      this.isRejecting = true
      await rejectEnrolmentRequest({
        enrolmentData: this.enrolmentData,
        team: 'taxnitro',
        reason,
      })
      this.isRejecting = false
      this.showRejectModal = false
    },
    async approve() {
      if (
        !this.form.apiKey ||
        !this.form.locationId ||
        this.isValidApiKey !== 'valid' ||
        this.isDuplicatedLocationID !== 'available'
      )
        return

      this.isApproving = true

      await approveEnrolmentRequest({
        enrolmentData: this.enrolmentData,
        team: 'taxnitro',
        apiKey: this.form.apiKey,
        locationID: this.form.locationId,
      })
      this.showApproveModal = false
      this.isApproving = false
    },
    async validateApiKey() {
      if (this.form.apiKey.trim() === '') {
        this.isValidApiKey = 'unchecked'
        return
      }
      const payload: CheckForValidHLAPIKeyPayload = {
        HLApiKey: this.form.apiKey,
      }
      const { data: isValid } = await functions.httpsCallable(
        FunctionNames.CHECK_FOR_VALID_HLAPIKEY
      )(payload)
      if (isValid) {
        this.isValidApiKey = 'valid'
      } else {
        this.isValidApiKey = 'invalid'
      }
    },
    async validateLocationID() {
      if (this.form.locationId.trim() === '') {
        this.isDuplicatedLocationID = 'unchecked'
        return
      }
      const payload: CheckForDuplicatedHLLocationIDPayload = {
        locationID: this.form.locationId,
      }
      const { data: isDuplicated } = await functions.httpsCallable(
        FunctionNames.CHECK_FOR_DUPLICATED_LOCATION_ID
      )(payload)
      if (isDuplicated) {
        this.isDuplicatedLocationID = 'duplicated'
      } else {
        this.isDuplicatedLocationID = 'available'
      }
    },
  },
  created() {
    this.debouncedValidateApiKey = debounce(this.validateApiKey, 300)
    this.debouncedValidateLocationID = debounce(this.validateLocationID, 300)
  },
})
