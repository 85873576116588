
import { defineComponent } from 'vue'
import BaseModal from '@/components/reusable/BaseModal.vue'

export default defineComponent({
  components: {
    BaseModal,
  },
  emits: ['close', 'confirm'],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
})
