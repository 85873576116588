
import { computed, defineComponent, onUnmounted, ref } from 'vue'
import BackButton from '@/components/reusable/BaseBackButton.vue'
import JotformData from './JotformData.vue'
import TaxNitroReviewProcess from './TaxNitroReviewProcess.vue'
import RightWayReviewProcess from './RightWayReviewProcess.vue'
import { EnrolmentRequestFirestoreDoc } from 'types/enrolment'
import { useRoute } from 'vue-router'
import { db } from '@/firebase'
import { FirestoreColNames } from '@/enums/FirestoreColNames'
import Spinner from '@/components/reusable/BaseSpinner.vue'
import { firestore } from 'firebase'

export default defineComponent({
  components: {
    BackButton,
    Spinner,
    TaxNitroReviewProcess,
    RightWayReviewProcess,
    JotformData,
  },
  setup() {
    const route = useRoute()
    const submissionID = route.params.id as string
    const errorMessage = ref('')

    const enrolmentData = ref<EnrolmentRequestFirestoreDoc | null>(null)

    const enrolDataListener = db
      .collection(FirestoreColNames.ENROLMENT_REQUESTS)
      .doc(submissionID)
      .onSnapshot(
        doc => {
          const data = doc.data() as EnrolmentRequestFirestoreDoc
          enrolmentData.value = data
        },
        err => {
          errorMessage.value = err.message
        }
      )

    onUnmounted(() => {
      enrolDataListener()
    })

    const isRejected = computed(() => enrolmentData.value?.rejected ?? false)
    const rejectedBy = computed(
      () => enrolmentData.value?.rejectedBy?.rejecterName ?? 'Unknown'
    )
    const rejectedAt = computed(() => {
      if (!enrolmentData.value?.rejectedBy?.rejectedAt) return 'unknown'
      const timestamp = enrolmentData.value?.rejectedBy
        ?.rejectedAt as firestore.Timestamp
      return timestamp.toDate().toLocaleString()
    })
    const rejectedReason = computed(
      () => enrolmentData.value?.rejectedBy?.reason ?? 'Unknown reason'
    )
    const rejectedTeam = computed(
      () => enrolmentData.value?.rejectedBy?.rejecterTeam ?? 'unknown team'
    )

    return {
      errorMessage,
      enrolmentData,
      isRejected,
      rejectedBy,
      rejectedAt,
      rejectedReason,
      rejectedTeam,
    }
  },
})
