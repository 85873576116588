<template>
  <back-button class="mt-6 ml-8" />
  <div v-if="!enrolmentData" class="w-screen mt-64">
    <spinner class="mx-auto " />
  </div>
  <div v-else class="max-w-xl mx-auto pb-40 px-3 sm:px-6 lg:px-8">
    <!-- Jotform data -->
    <jotform-data :enrolmentData="enrolmentData" />
    <a href="#taxnitro">
      <svg
        class="w-10 h-10 mx-auto text-gray-600 my-8"
        stroke-width="2"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="{2}"
          d="M19 13l-7 7-7-7m14-8l-7 7-7-7"
        />
      </svg>
    </a>
    <div v-if="isRejected">
      <p class="text-sm text-red-500">
        This request has been rejected by
        <span class="font-semibold">{{ rejectedBy }}</span> as a
        <span class="font-semibold">{{ rejectedTeam }} representative</span> at
        {{ rejectedAt }} with reason of "<span class="font-semibold">{{
          rejectedReason
        }}</span
        >"
      </p>
    </div>
    <div v-else>
      <!-- Tax Nitro review process -->
      <tax-nitro-review-process id="taxnitro" :enrolmentData="enrolmentData" />
      <a href="#rightway">
        <svg
          class="w-10 h-10 mx-auto text-gray-600 my-8"
          stroke-width="2"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="{2}"
            d="M19 13l-7 7-7-7m14-8l-7 7-7-7"
          />
        </svg>
      </a>
      <!-- Right Way review process -->
      <right-way-review-process id="rightway" :enrolmentData="enrolmentData" />
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onUnmounted, ref } from 'vue'
import BackButton from '@/components/reusable/BaseBackButton.vue'
import JotformData from './JotformData.vue'
import TaxNitroReviewProcess from './TaxNitroReviewProcess.vue'
import RightWayReviewProcess from './RightWayReviewProcess.vue'
import { EnrolmentRequestFirestoreDoc } from 'types/enrolment'
import { useRoute } from 'vue-router'
import { db } from '@/firebase'
import { FirestoreColNames } from '@/enums/FirestoreColNames'
import Spinner from '@/components/reusable/BaseSpinner.vue'
import { firestore } from 'firebase'

export default defineComponent({
  components: {
    BackButton,
    Spinner,
    TaxNitroReviewProcess,
    RightWayReviewProcess,
    JotformData,
  },
  setup() {
    const route = useRoute()
    const submissionID = route.params.id as string
    const errorMessage = ref('')

    const enrolmentData = ref<EnrolmentRequestFirestoreDoc | null>(null)

    const enrolDataListener = db
      .collection(FirestoreColNames.ENROLMENT_REQUESTS)
      .doc(submissionID)
      .onSnapshot(
        doc => {
          const data = doc.data() as EnrolmentRequestFirestoreDoc
          enrolmentData.value = data
        },
        err => {
          errorMessage.value = err.message
        }
      )

    onUnmounted(() => {
      enrolDataListener()
    })

    const isRejected = computed(() => enrolmentData.value?.rejected ?? false)
    const rejectedBy = computed(
      () => enrolmentData.value?.rejectedBy?.rejecterName ?? 'Unknown'
    )
    const rejectedAt = computed(() => {
      if (!enrolmentData.value?.rejectedBy?.rejectedAt) return 'unknown'
      const timestamp = enrolmentData.value?.rejectedBy
        ?.rejectedAt as firestore.Timestamp
      return timestamp.toDate().toLocaleString()
    })
    const rejectedReason = computed(
      () => enrolmentData.value?.rejectedBy?.reason ?? 'Unknown reason'
    )
    const rejectedTeam = computed(
      () => enrolmentData.value?.rejectedBy?.rejecterTeam ?? 'unknown team'
    )

    return {
      errorMessage,
      enrolmentData,
      isRejected,
      rejectedBy,
      rejectedAt,
      rejectedReason,
      rejectedTeam,
    }
  },
})
</script>

<style scoped></style>
