<template>
  <div>
    <div v-if="alreadyReviewed">
      <p class="text-lg font-semibold text-blue-700">
        Right Way Review Process
      </p>
      <p class="text-sm italic text-green-500">
        {{ alreadyReviewed.reviewerName }} had reviewed this request on
        {{ alreadyReviewed.reviewedAt }} as a RightWay representative.
      </p>
    </div>
    <div v-else>
      <p class="text-xs text-right font-semibold text-opacity-75 text-gray-700">
        You're reviewing as
        <span class="text-brand">{{ userRole }}</span
        >.
      </p>
      <p
        v-if="!isAllowToReview"
        class="text-right text-xs font-semibold text-yellow-600"
      >
        You do not have permission to conduct review on Right Way.
      </p>
      <div
        class="mt-1 text-gray-700 p-10 mx-auto shadow-lg overflow-hidden border-b border-gray-200 sm:rounded-lg bg-opacity-50 bg-blue-200"
        :class="{ 'filter-grayscale': !isAllowToReview }"
      >
        <p class="text-lg font-semibold text-blue-700">
          Right Way Review Process
        </p>
        <p class="mt-4 text-sm">
          Make sure to allow API access for these specific
          <span class="font-bold">EFINs</span> internally within
          RightWay/Magentrix before approving this integration request.
        </p>
        <div v-if="!isEditingEFINs">
          <ul class="list-disc ml-8 my-6">
            <li
              v-for="EFIN in associatedEFINs"
              :key="EFIN"
              class="my-2 font-semibold"
            >
              {{ EFIN }}
            </li>
          </ul>
          <button
            class="focus:outline-none ml-1 text-blue-600 hover:text-blue-500 text-sm font-semibold flex items-start space-x-1"
            @click="startEditEFINs"
          >
            <p>
              Edit EFINs
            </p>
            <EditIcon class="w-4 h-4" />
          </button>
        </div>
        <div v-else class="my-4">
          <input
            type="text"
            name="editEFINsInput"
            id="editEFINsInput"
            class="py-1 px-3 border-2 rounded-md outline-none focus:border-brand"
            v-model="onEditEFINsValue"
          />
          <p class="text-xs font-semibold text-gray-500 my-4 ml-1">
            Note: Separate EFIN by a comma for multiple EFINs
          </p>
          <a
            href="#"
            class="flex items-center mt-4 focus:outline-none ml-1 text-green-600 hover:text-green-500 text-sm font-semibold"
            @click.prevent="saveEditedEFINs"
            >Finish Editing <CheckIcon class="ml-2 w-4 h-4"
          /></a>
        </div>

        <div class="mt-6 text-sm ml-2 flex items-center">
          <input
            v-model="form.confirmAPIAccess"
            :disabled="!isAllowToReview"
            type="checkbox"
            name="confirmCampaign"
            class="transform scale-150"
          />
          <p class="ml-4">
            I confirm that the above EFINs had been given access internally via
            Magentrix API.
          </p>
        </div>
        <p class="text-right mr-2 text-xs mb-2 mt-4 inline-block">
          The enrolling company will immediately be included in the system
          scheduled data sync once you clicked "Approve".
        </p>
        <p
          v-if="errorMessage"
          class="text-right mr-4 text-xs mb-2 mt-4 font-semibold text-red-600"
        >
          {{ errorMessage }}
        </p>
        <div class="flex justify-between w-full px-4">
          <button
            :disabled="!isAllowToReview"
            class="mt-3 w-32 relative flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-yellow-500 hover:bg-yellow-600 focus:outline-none transition duration-150 ease-in-out disabled:opacity-50 disabled:text-gray-500"
            @click="showRejectModal = true"
          >
            Reject
          </button>
          <button
            :disabled="!isAllowToReview || !form.confirmAPIAccess"
            class="mt-3 ml-3 w-32 relative flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-blue-500 hover:bg-green-500 focus:outline-none transition duration-150 ease-in-out disabled:opacity-50 disabled:text-gray-500"
            @click="showApproveModal = true"
          >
            Approve
          </button>
        </div>
      </div>
      <reject-confirm-modal
        :show="showRejectModal"
        :loading="isRejecting"
        @close="showRejectModal = false"
        @confirm="reject"
      />
      <approve-confirm-modal
        :show="showApproveModal"
        :loading="isApproving"
        @close="showApproveModal = false"
        @confirm="approve"
      />
    </div>
  </div>
</template>

<script lang="ts">
import {
  approveEnrolmentRequest,
  rejectEnrolmentRequest,
} from '@/composables/useEnrolmentRequests'
import { db, userData } from '@/firebase'
import { RouteName } from '@/router/RouteName'
import { firestore } from 'firebase'
import { EnrolmentRequestFirestoreDoc } from 'types/enrolment'
import { defineComponent, PropType } from 'vue'
import ApproveConfirmModal from './ApproveConfirmModal.vue'
import RejectConfirmModal from './RejectConfirmModal.vue'
import EditIcon from '../../icons/EditIcon.vue'
import CheckIcon from '../../icons/CheckIcon.vue'
import { FirestoreColNames } from '@/enums/FirestoreColNames'

export default defineComponent({
  components: {
    ApproveConfirmModal,
    RejectConfirmModal,
    EditIcon,
    CheckIcon,
  },
  props: {
    enrolmentData: {
      type: Object as PropType<EnrolmentRequestFirestoreDoc>,
      required: true,
    },
  },
  data() {
    return {
      showApproveModal: false,
      showRejectModal: false,
      isApproving: false,
      isRejecting: false,
      isEditingEFINs: false,
      onEditEFINsValue: '',
      form: {
        confirmAPIAccess: false,
      },
      errorMessage: '',
    }
  },
  computed: {
    userRole() {
      return userData?.claims?.role ?? 'unauthorized'
    },
    associatedEFINs(): string[] {
      return this.enrolmentData?.EFINs ?? ['Not found.']
    },
    taxNitroReviewed(): boolean {
      return this.enrolmentData?.taxNitroReviewed ?? false
    },
    isAllowToReview(): boolean {
      return (
        this.taxNitroReviewed &&
        (this.userRole === 'admin' || this.userRole === 'rightway-reviewer')
      )
    },
    alreadyReviewed(): null | { reviewerName: string; reviewedAt: string } {
      const isReviewed = this.enrolmentData?.rightWayReviewed ?? false
      if (!isReviewed) return null
      const reviewerName =
        this.enrolmentData?.rightWayReviewer?.reviewerName || 'Unknown'
      const timestamp = this.enrolmentData?.rightWayReviewer
        ?.reviewedAt as firestore.Timestamp | null
      const reviewedAt = timestamp?.toDate().toLocaleString() ?? 'Unknown'
      return {
        reviewerName,
        reviewedAt,
      }
    },
  },
  methods: {
    async reject(reason: string) {
      if (!reason) return
      this.isRejecting = true
      await rejectEnrolmentRequest({
        enrolmentData: this.enrolmentData,
        team: 'rightway',
        reason,
      })
      this.isRejecting = false
      this.showRejectModal = false
    },
    async approve() {
      if (!this.isAllowToReview) return

      this.isApproving = true
      // await this.$store.dispatch(ActionTypes.APPROVE_ENROLMENT_REQ, {
      //   team: 'rightway',
      //   submissionID: this.submissionID,
      // })
      await approveEnrolmentRequest({
        enrolmentData: this.enrolmentData,
        team: 'rightway',
      })
      this.showApproveModal = false
      this.isApproving = false
      this.$router.push({ name: RouteName.ENROLMENT })
    },
    startEditEFINs() {
      this.onEditEFINsValue = this.enrolmentData.EFINs?.join(', ') ?? ''
      this.isEditingEFINs = true
    },
    async saveEditedEFINs() {
      //
      if (!this.enrolmentData.submissionID) {
        this.errorMessage = 'Failed to edit EFINs, contact support.'
        return
      }
      const newEFINs = this.onEditEFINsValue.split(',').map(EFIN => EFIN.trim())

      await db
        .collection(FirestoreColNames.ENROLMENT_REQUESTS)
        .doc(this.enrolmentData.submissionID)
        .set({ EFINs: newEFINs }, { merge: true })
      this.isEditingEFINs = false
    },
  },
})
</script>

<style scoped></style>
