
import {
  approveEnrolmentRequest,
  rejectEnrolmentRequest,
} from '@/composables/useEnrolmentRequests'
import { db, userData } from '@/firebase'
import { RouteName } from '@/router/RouteName'
import { firestore } from 'firebase'
import { EnrolmentRequestFirestoreDoc } from 'types/enrolment'
import { defineComponent, PropType } from 'vue'
import ApproveConfirmModal from './ApproveConfirmModal.vue'
import RejectConfirmModal from './RejectConfirmModal.vue'
import EditIcon from '../../icons/EditIcon.vue'
import CheckIcon from '../../icons/CheckIcon.vue'
import { FirestoreColNames } from '@/enums/FirestoreColNames'

export default defineComponent({
  components: {
    ApproveConfirmModal,
    RejectConfirmModal,
    EditIcon,
    CheckIcon,
  },
  props: {
    enrolmentData: {
      type: Object as PropType<EnrolmentRequestFirestoreDoc>,
      required: true,
    },
  },
  data() {
    return {
      showApproveModal: false,
      showRejectModal: false,
      isApproving: false,
      isRejecting: false,
      isEditingEFINs: false,
      onEditEFINsValue: '',
      form: {
        confirmAPIAccess: false,
      },
      errorMessage: '',
    }
  },
  computed: {
    userRole() {
      return userData?.claims?.role ?? 'unauthorized'
    },
    associatedEFINs(): string[] {
      return this.enrolmentData?.EFINs ?? ['Not found.']
    },
    taxNitroReviewed(): boolean {
      return this.enrolmentData?.taxNitroReviewed ?? false
    },
    isAllowToReview(): boolean {
      return (
        this.taxNitroReviewed &&
        (this.userRole === 'admin' || this.userRole === 'rightway-reviewer')
      )
    },
    alreadyReviewed(): null | { reviewerName: string; reviewedAt: string } {
      const isReviewed = this.enrolmentData?.rightWayReviewed ?? false
      if (!isReviewed) return null
      const reviewerName =
        this.enrolmentData?.rightWayReviewer?.reviewerName || 'Unknown'
      const timestamp = this.enrolmentData?.rightWayReviewer
        ?.reviewedAt as firestore.Timestamp | null
      const reviewedAt = timestamp?.toDate().toLocaleString() ?? 'Unknown'
      return {
        reviewerName,
        reviewedAt,
      }
    },
  },
  methods: {
    async reject(reason: string) {
      if (!reason) return
      this.isRejecting = true
      await rejectEnrolmentRequest({
        enrolmentData: this.enrolmentData,
        team: 'rightway',
        reason,
      })
      this.isRejecting = false
      this.showRejectModal = false
    },
    async approve() {
      if (!this.isAllowToReview) return

      this.isApproving = true
      // await this.$store.dispatch(ActionTypes.APPROVE_ENROLMENT_REQ, {
      //   team: 'rightway',
      //   submissionID: this.submissionID,
      // })
      await approveEnrolmentRequest({
        enrolmentData: this.enrolmentData,
        team: 'rightway',
      })
      this.showApproveModal = false
      this.isApproving = false
      this.$router.push({ name: RouteName.ENROLMENT })
    },
    startEditEFINs() {
      this.onEditEFINsValue = this.enrolmentData.EFINs?.join(', ') ?? ''
      this.isEditingEFINs = true
    },
    async saveEditedEFINs() {
      //
      if (!this.enrolmentData.submissionID) {
        this.errorMessage = 'Failed to edit EFINs, contact support.'
        return
      }
      const newEFINs = this.onEditEFINsValue.split(',').map(EFIN => EFIN.trim())

      await db
        .collection(FirestoreColNames.ENROLMENT_REQUESTS)
        .doc(this.enrolmentData.submissionID)
        .set({ EFINs: newEFINs }, { merge: true })
      this.isEditingEFINs = false
    },
  },
})
