
import { defineComponent } from 'vue'
import BaseModal from '@/components/reusable/BaseModal.vue'

export default defineComponent({
  components: {
    BaseModal,
  },
  emits: ['close', 'confirm'],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      reason: '',
    }
  },
  methods: {
    confirm(): void {
      if (!this.reason) return
      this.$emit('confirm', this.reason)
    },
  },
})
