<template>
  <p class="text-xs text-right font-semibold text-opacity-75 text-gray-700">
    Jotform Submission ID: {{ submissionID }}
  </p>
  <p class="text-xs text-right font-semibold text-opacity-75 text-gray-700">
    Created at: {{ createdAt }}
  </p>
  <div
    v-if="enrolmentData"
    class="mt-1 text-gray-700 p-10 mx-auto shadow-lg overflow-hidden border-b border-gray-200 sm:rounded-lg bg-gray-300 bg-opacity-50"
  >
    <div class="leading-6">
      <h6 class="text-xs text-gray-600">
        Company Name on TaxNitro
      </h6>
      <p class="text-xl font-semibold">
        {{ companyNameOnTaxNitro }}
      </p>
    </div>
    <div class="leading-6 mt-5">
      <h6 class="text-xs text-gray-600">
        Email
      </h6>
      <p class="text-xl font-semibold">
        {{ emailOnTaxNitro }}
      </p>
    </div>
    <div class="leading-6 mt-5">
      <h6 class="text-xs text-gray-600">
        Full Name
      </h6>
      <p class="text-xl font-semibold">
        {{ fullName }}
      </p>
    </div>
    <div class="leading-6 mt-5">
      <h6 class="text-xs text-gray-600">
        Phone Number
      </h6>
      <p class="text-xl font-semibold">
        {{ phoneNumber }}
      </p>
    </div>
    <div class="leading-6 mt-5">
      <h6 class="text-xs text-gray-600">
        Campaigns to enroll
      </h6>
      <ul class="list-disc ml-5 font-semibold">
        <li v-for="campaign in campaignsToEnroll" :key="campaign">
          {{ campaign }}
        </li>
      </ul>
    </div>
    <div class="leading-6 mt-5">
      <h6 class="text-xs text-gray-600">
        Associated EFINs
      </h6>
      <ul class="list-disc ml-5 font-semibold">
        <li v-for="EFIN in associatedEFINs" :key="EFIN">
          {{ EFIN }}
        </li>
      </ul>
    </div>
    <div class="mt-8 mr-6 text-right">
      <h6 class="text-sm text-gray-600 mr-2">
        Signature
      </h6>
      <img
        class="float-right border border-gray-400 rounded"
        :src="signatureInlineData"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { RouteName } from '@/router/RouteName'
import { EnrolmentRequestFirestoreDoc } from 'types/enrolment'

import { defineComponent, PropType } from 'vue'

export default defineComponent({
  props: {
    enrolmentData: {
      type: Object as PropType<EnrolmentRequestFirestoreDoc>,
      required: true,
    },
  },
  data() {
    return {
      RouteName: RouteName,
    }
  },
  computed: {
    submissionID(): string {
      return this.$route.params.id as string
    },
    createdAt(): string {
      const serverTimestamp = this.enrolmentData
        ?.createdAt as firebase.firestore.Timestamp
      if (!serverTimestamp) return 'Unknown'
      return serverTimestamp.toDate().toLocaleString()
    },
    companyNameOnTaxNitro(): string {
      return this.enrolmentData?.companyNameOnNitro ?? ''
    },
    emailOnTaxNitro(): string {
      return this.enrolmentData?.emailOnNitro ?? ''
    },
    fullName(): string {
      return (
        this.enrolmentData?.name.first + ' ' + this.enrolmentData?.name.last ??
        ''
      )
    },
    phoneNumber(): string {
      return this.enrolmentData?.phoneNumber.full ?? ''
    },
    campaignsToEnroll(): string[] {
      return this.enrolmentData?.whichCampaigns ?? ['Non chosen']
    },
    associatedEFINs(): string[] {
      return this.enrolmentData?.EFINs ?? ['Not found.']
    },
    signatureInlineData(): string {
      return this.enrolmentData?.bySigningInlineImage ?? ''
    },
  },
})
</script>

<style scoped></style>
