<template>
  <button
    class="text-gray-700 hover:text-brand flex items-center transition-colors ease-out duration-150"
    @click="goBack"
  >
    <svg
      class="h-8 w-8"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="{2}"
        d="M7 16l-4-4m0 0l4-4m-4 4h18"
      />
    </svg>
    <p class="text-sm pb-1 ml-2">Back</p>
  </button>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  methods: {
    goBack(): void {
      this.$router.go(-1)
    },
  },
})
</script>

<style scoped></style>
