<template>
  <div>
    <div v-if="alreadyReviewed">
      <p class="text-lg font-semibold text-brand">Tax Nitro Review Process</p>
      <p class="text-sm italic text-green-500">
        {{ alreadyReviewed.reviewerName }} had reviewed this request on
        {{ alreadyReviewed.reviewedAt }} as a TaxNitro representative.
      </p>
    </div>
    <div v-else>
      <p class="text-xs text-right font-semibold text-opacity-75 text-gray-700">
        You're reviewing as
        <span class="text-brand">{{ userRole }}</span
        >.
      </p>
      <p
        v-if="!isAllowToReview"
        class="text-right text-xs font-semibold text-yellow-600"
      >
        You do not have permission to conduct review on Tax Nitro.
      </p>
      <div
        class="mt-1 text-gray-700 p-10 mx-auto shadow-lg overflow-hidden border-b border-gray-200 sm:rounded-lg bg-opacity-50 bg-brand-light"
        :class="{ 'filter-grayscale': !isAllowToReview }"
      >
        <p class="text-lg font-semibold text-brand">Tax Nitro Review Process</p>
        <p class="text-sm mt-4">
          1. Please fill in the following information of the enrolling company,
          <span class="font-bold">{{ companyNameOnTaxNitro }}</span
          >.
        </p>

        <div class="my-4">
          <p class="text-sm font-semibold mb-1">Location ID</p>
          <input
            v-model="form.locationId"
            :disabled="!isAllowToReview"
            class="text-sm px-3 py-2 w-3/4 rounded border border-gray-400 focus:border-brand focus:outline-none"
            placeholder="e.g: bjDaiVVftRcgsrGSuZpi"
            type="text"
            @input="debouncedValidateLocationID"
          />
          <p
            class="text-xs mt-1 font-semibold"
            :class="{
              'text-red-600': isDuplicatedLocationID === 'duplicated',
              'text-green-600': isDuplicatedLocationID === 'available',
            }"
          >
            {{
              isDuplicatedLocationID === 'unchecked'
                ? ''
                : isDuplicatedLocationID === 'available'
                ? 'The Location ID is available.'
                : 'Found duplicated Location ID'
            }}
          </p>
        </div>
        <div class="my-4">
          <p class="text-sm font-semibold mb-1">API key</p>
          <input
            v-model="form.apiKey"
            :disabled="!isAllowToReview"
            class="text-sm px-3 py-2 w-3/4 rounded border border-gray-400 focus:border-brand focus:outline-none"
            placeholder="e.g: 66d008bd-68d9-4587-9a34-973ff27c47e9"
            type="text"
            @input="debouncedValidateApiKey"
          />
          <p
            class="text-xs mt-1 font-semibold"
            :class="{
              'text-red-600': isValidApiKey === 'invalid',
              'text-green-600': isValidApiKey === 'valid',
            }"
          >
            {{
              isValidApiKey === 'unchecked'
                ? ''
                : isValidApiKey === 'valid'
                ? 'The API key is valid.'
                : 'The API key is invalid'
            }}
          </p>
        </div>
        <p class="text-sm mt-8">
          2. Also, please set up the required Triggers for the company's
          requested campaigns before approving.
        </p>
        <ul class="list-disc ml-8 mt-4 font-semibold text-sm">
          <li v-for="campaign in campaignsToEnroll" :key="campaign">
            {{ campaign }}
          </li>
        </ul>
        <div class="mt-6 text-sm ml-2 flex items-center">
          <input
            v-model="form.confirmCampaign"
            :disabled="!isAllowToReview"
            type="checkbox"
            name="confirmCampaign"
            class="transform scale-150"
          />
          <p class="ml-4">
            I confirm that the above Triggers had been set up.
          </p>
        </div>
        <p class="text-right mr-2 text-xs inline-block mt-8">
          Above details will be used to integrate the system. After clicking
          "Approve", the request will be sent to the next reviewer (RightWay).
        </p>
        <div class="flex justify-between w-full px-4">
          <button
            :disabled="!isAllowToReview"
            class="mt-3 w-32 relative flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-yellow-500 hover:bg-yellow-600 focus:outline-none transition duration-150 ease-in-out disabled:opacity-50 disabled:text-gray-500"
            @click="showRejectModal = true"
          >
            Reject
          </button>
          <button
            :disabled="
              isValidApiKey !== 'valid' ||
                isDuplicatedLocationID !== 'available' ||
                form.locationId === '' ||
                !isAllowToReview ||
                !form.confirmCampaign
            "
            class="mt-3 ml-3 w-32 relative flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-brand hover:bg-green-500 focus:outline-none transition duration-150 ease-in-out disabled:opacity-75 disabled:bg-gray-400 disabled:text-gray-600"
            @click="showApproveModal = true"
          >
            Approve
          </button>
        </div>
      </div>
      <reject-confirm-modal
        :show="showRejectModal"
        :loading="isRejecting"
        @close="showRejectModal = false"
        @confirm="reject"
      />
      <approve-confirm-modal
        :show="showApproveModal"
        :loading="isApproving"
        @close="showApproveModal = false"
        @confirm="approve"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { DebouncedFunc, debounce } from 'lodash'
import { EnrolmentRequestFirestoreDoc } from 'types/enrolment'
import ApproveConfirmModal from './ApproveConfirmModal.vue'
import RejectConfirmModal from './RejectConfirmModal.vue'
import { firestore } from 'firebase'
import { functions, userData } from '@/firebase'
import { FunctionNames } from '@/enums/FunctionNames'
import {
  CheckForValidHLAPIKeyPayload,
  CheckForDuplicatedHLLocationIDPayload,
} from 'types/callable'
import {
  approveEnrolmentRequest,
  rejectEnrolmentRequest,
} from '@/composables/useEnrolmentRequests'

export default defineComponent({
  components: {
    ApproveConfirmModal,
    RejectConfirmModal,
  },
  props: {
    enrolmentData: {
      type: Object as PropType<EnrolmentRequestFirestoreDoc>,
      required: true,
    },
  },
  data() {
    return {
      showApproveModal: false,
      showRejectModal: false,
      form: {
        apiKey: '',
        locationId: '',
        confirmCampaign: false,
      },
      isApproving: false,
      isRejecting: false,
      isValidApiKey: 'unchecked' as 'unchecked' | 'valid' | 'invalid',
      isDuplicatedLocationID: 'unchecked' as
        | 'unchecked'
        | 'duplicated'
        | 'available',
      debouncedValidateApiKey: null as DebouncedFunc<() => void> | null,
      debouncedValidateLocationID: null as DebouncedFunc<() => void> | null,
    }
  },
  computed: {
    userRole() {
      return userData?.claims?.role ?? 'unauthorized'
    },
    isAllowToReview(): boolean {
      return this.userRole === 'admin' || this.userRole === 'taxnitro-reviewer'
    },
    alreadyReviewed(): null | { reviewerName: string; reviewedAt: string } {
      const isReviewed = this.enrolmentData?.taxNitroReviewed ?? false
      if (!isReviewed) return null
      const reviewerName =
        this.enrolmentData?.taxNitroReviewer?.reviewerName || 'Unknown'
      const timestamp = this.enrolmentData?.taxNitroReviewer
        ?.reviewedAt as firestore.Timestamp | null
      const reviewedAt = timestamp?.toDate().toLocaleString() ?? 'Unknown'
      return {
        reviewerName,
        reviewedAt,
      }
    },
    companyNameOnTaxNitro(): string {
      return this.enrolmentData?.companyNameOnNitro ?? ''
    },
    campaignsToEnroll(): string[] {
      return this.enrolmentData?.whichCampaigns ?? ['Non chosen']
    },
  },
  methods: {
    async reject(reason: string) {
      if (!reason) return
      this.isRejecting = true
      await rejectEnrolmentRequest({
        enrolmentData: this.enrolmentData,
        team: 'taxnitro',
        reason,
      })
      this.isRejecting = false
      this.showRejectModal = false
    },
    async approve() {
      if (
        !this.form.apiKey ||
        !this.form.locationId ||
        this.isValidApiKey !== 'valid' ||
        this.isDuplicatedLocationID !== 'available'
      )
        return

      this.isApproving = true

      await approveEnrolmentRequest({
        enrolmentData: this.enrolmentData,
        team: 'taxnitro',
        apiKey: this.form.apiKey,
        locationID: this.form.locationId,
      })
      this.showApproveModal = false
      this.isApproving = false
    },
    async validateApiKey() {
      if (this.form.apiKey.trim() === '') {
        this.isValidApiKey = 'unchecked'
        return
      }
      const payload: CheckForValidHLAPIKeyPayload = {
        HLApiKey: this.form.apiKey,
      }
      const { data: isValid } = await functions.httpsCallable(
        FunctionNames.CHECK_FOR_VALID_HLAPIKEY
      )(payload)
      if (isValid) {
        this.isValidApiKey = 'valid'
      } else {
        this.isValidApiKey = 'invalid'
      }
    },
    async validateLocationID() {
      if (this.form.locationId.trim() === '') {
        this.isDuplicatedLocationID = 'unchecked'
        return
      }
      const payload: CheckForDuplicatedHLLocationIDPayload = {
        locationID: this.form.locationId,
      }
      const { data: isDuplicated } = await functions.httpsCallable(
        FunctionNames.CHECK_FOR_DUPLICATED_LOCATION_ID
      )(payload)
      if (isDuplicated) {
        this.isDuplicatedLocationID = 'duplicated'
      } else {
        this.isDuplicatedLocationID = 'available'
      }
    },
  },
  created() {
    this.debouncedValidateApiKey = debounce(this.validateApiKey, 300)
    this.debouncedValidateLocationID = debounce(this.validateLocationID, 300)
  },
})
</script>

<style scoped></style>
