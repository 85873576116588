
import { RouteName } from '@/router/RouteName'
import { EnrolmentRequestFirestoreDoc } from 'types/enrolment'

import { defineComponent, PropType } from 'vue'

export default defineComponent({
  props: {
    enrolmentData: {
      type: Object as PropType<EnrolmentRequestFirestoreDoc>,
      required: true,
    },
  },
  data() {
    return {
      RouteName: RouteName,
    }
  },
  computed: {
    submissionID(): string {
      return this.$route.params.id as string
    },
    createdAt(): string {
      const serverTimestamp = this.enrolmentData
        ?.createdAt as firebase.firestore.Timestamp
      if (!serverTimestamp) return 'Unknown'
      return serverTimestamp.toDate().toLocaleString()
    },
    companyNameOnTaxNitro(): string {
      return this.enrolmentData?.companyNameOnNitro ?? ''
    },
    emailOnTaxNitro(): string {
      return this.enrolmentData?.emailOnNitro ?? ''
    },
    fullName(): string {
      return (
        this.enrolmentData?.name.first + ' ' + this.enrolmentData?.name.last ??
        ''
      )
    },
    phoneNumber(): string {
      return this.enrolmentData?.phoneNumber.full ?? ''
    },
    campaignsToEnroll(): string[] {
      return this.enrolmentData?.whichCampaigns ?? ['Non chosen']
    },
    associatedEFINs(): string[] {
      return this.enrolmentData?.EFINs ?? ['Not found.']
    },
    signatureInlineData(): string {
      return this.enrolmentData?.bySigningInlineImage ?? ''
    },
  },
})
