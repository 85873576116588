<template>
  <base-modal :show="show">
    <div class="bg-white px-4 pt-5 pb-4 sm:p-8 sm:pb-4">
      <div class="sm:flex sm:items-start">
        <div
          class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-brand-light bg-opacity-50 sm:mx-0 sm:h-10 sm:w-10"
        >
          <!-- Heroicon name: exclamation -->
          <svg
            class="h-6 w-6 text-orange-500"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
            />
          </svg>
        </div>
        <div class="mt-3 text-center sm:mt-0 sm:ml-6 sm:text-left">
          <h3
            class="text-lg leading-6 font-medium text-gray-900"
            id="modal-headline"
          >
            Rejecting enrollment request
          </h3>
          <div class="mt-2">
            <p class="text-sm leading-5 text-gray-500">
              Please provide remarks on the rejection.
            </p>
          </div>
          <div>
            <textarea
              v-model="reason"
              name="reject-reason"
              id="reject-reason"
              rows="4"
              cols="30"
              class="border rounded text-gray-700 outline-none px-4 py-2 mt-3 resize-none overflow-auto"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
      <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
        <button
          :disabled="loading || reason === ''"
          type="button"
          class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-red-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-red-500 focus:outline-none focus:border-green-700 focus:shadow-outline-red transition ease-in-out duration-150 sm:text-sm sm:leading-5 disabled:bg-gray-400"
          @click="confirm"
        >
          Reject
        </button>
      </span>
      <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
        <button
          type="button"
          class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5"
          @click="$emit('close')"
        >
          Cancel
        </button>
      </span>
    </div>
  </base-modal>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import BaseModal from '@/components/reusable/BaseModal.vue'

export default defineComponent({
  components: {
    BaseModal,
  },
  emits: ['close', 'confirm'],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      reason: '',
    }
  },
  methods: {
    confirm(): void {
      if (!this.reason) return
      this.$emit('confirm', this.reason)
    },
  },
})
</script>

<style scoped></style>
