
import { defineComponent } from 'vue'

export default defineComponent({
  methods: {
    goBack(): void {
      this.$router.go(-1)
    },
  },
})
